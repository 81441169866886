import { createRouter, createWebHistory } from 'vue-router';
import { VueNavigationClient } from './route-helper.js';
import { registerGuard } from "./guard.js";
import path from 'path';

const routes = [
  {
    path: "/",
    name: "loading",
    component: () => import('../view/LoadingScreen.vue'),
    meta: { layout: "HeadlessLayout" },
  },
  {
    path: "/screening",
    name: "screening",
    component: () => import('../view/Screening.vue'),
    meta: { layout: "IssueLayout", breadcrumb: 'Problem Medical Bill Assessment' },
  },
  {
    path: "/charity-care",
    name: "charity-care",
    component: () => import('../view/Charity.vue'),
    meta: {  layout: "CustomerDashboardLayout" },
  },
  //Does the page requires Authentication?
  {
    path: "/who-are-you",
    name: "whoAreYou",
    component: () => import('../view/issues/steps/WhoAreYou.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: "/other-resources",
    name: "other-resources",
    component: () => import('../view/OtherResources.vue'),
    meta: { requiresAuth: true, layout: "IssueLayout", breadcrumb: 'Problem Medical Bill Assessment' },
  },
  {
    path: "/customer-account-dashboard",
    redirect: { name: "dashboard" },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import('../view/CustomerDashboard.vue'),
    meta: {
      requiresAuth: true,
      title: "Dashboard",
      layout: "CustomerDashboardLayout",
    },
  },
  {
    path: "/issues/:slug",
    meta: { title: "Case #" },
    children: [
      {
        path: "",
        name: "issues.details",
        component: () => import('../view/issues/single/Single.vue'),
        meta: { requiresAuth: true, layout: 'CustomerDashboardLayout' }
      },
      {
        path: 'metrics',
        name: 'issues.metrics',
        component: () => import('../view/issues/single/Metrics.vue'),
        meta: { requiresAuth: true, layout: 'CustomerDashboardLayout' }
      },
      {
        path: "before-started",
        name: "issues.beforeStarted",
        component: () => import('../view/BeforeStarted.vue'),
        meta: { requiresAuth: true, layout: "IssueLayout" },
      },
      {
        path: "who-are-you",
        name: "issues.whoAreYou",
        component: () => import('../view/issues/steps/WhoAreYou.vue'),
        meta: { requiresAuth: true, layout: "IssueLayout" },
      },
      // {
      //   path: "ineligible",
      //   name: "issues.ineligible",
      //   component: () => import('../view/issues/steps/Ineligible.vue'),
      //   meta: { requiresAuth: true, layout: "IssueLayout" },
      // },
      {
        path: "patient-information",
        name: "issues.patientInformation",
        component: () => import('../view/issues/steps/PatientInformation.vue'),
        meta: { requiresAuth: true, layout: "IssueLayout" },
      },
      {
        path: "provider-details",
        name: "issues.providerDetails",
        component: () => import('../view/issues/steps/ProviderDetails.vue'),
        meta: { requiresAuth: true, layout: "IssueLayout" },
      },
      {
        path: "medical-bill-details",
        name: "issues.medicalBillDetails",
        component: () => import('../view/issues/steps/MedicalBillDetails.vue'),
        meta: { requiresAuth: true, layout: "IssueLayout" },
      },
      {
        path: "look-at-bill",
        name: "issues.lookAtBill",
        component: () => import('../view/issues/steps/LookAtBill.vue'),
        meta: { requiresAuth: true, layout: "IssueLayout" },
      },
      {
        path: "insurance",
        name: "issues.insurance",
        component: () => import('../view/issues/steps/Insurance.vue'),
        meta: { requiresAuth: true, layout: "IssueLayout" },
      },
      {
        path: "insurance-contact-info",
        name: "issues.contactInfo",
        component: () => import('../view/issues/steps/ContactInfo.vue'),
        meta: { requiresAuth: true, layout: "IssueLayout" },
      },
      {
        path: "insurance-policy-details",
        name: "issues.policyDetails",
        component: () => import('../view/issues/steps/InsurancePolicyDetails.vue'),
        meta: { requiresAuth: true, layout: "IssueLayout" },
      },
      {
        path: "insurance-payments",
        name: "issues.insurancePayments",
        component: () => import('../view/issues/steps/InsurancePayments.vue'),
        meta: { requiresAuth: true, layout: "IssueLayout" },
      },
      {
        path: "insurance-eob-details",
        name: "issues.eobDetails",
        component: () => import('../view/issues/steps/InsuranceEOB.vue'),
        meta: { requiresAuth: true, layout: "IssueLayout" },
      },
      {
        path: "medical-provider-and-services",
        name: "issues.medicalProviderAndServices",
        component: () => import('../view/issues/steps/MedicalProviderServices.vue'),
        meta: { requiresAuth: true, layout: "IssueLayout" },
      },
      {
        path: "dispute-details",
        name: "issues.disputeDetails",
        component: () => import('../view/issues/steps/DisputeDetails.vue'),
        meta: { requiresAuth: true, layout: "IssueLayout" },
      },
      {
        path: "dispute-details-agreements",
        name: "issues.disputeDetailsAgreements",
        component: () => import('../view/issues/steps/DisputeDetailsAgreements.vue'),
        meta: { requiresAuth: true, layout: "IssueLayout" },
      },
      {
        path: "good-faith-estimate",
        name: "issues.disputeDetailsGfe",
        component: () => import('../view/issues/steps/DisputeDetailsGFE.vue'),
        meta: { requiresAuth: true, layout: "IssueLayout" },
      },
      {
        path: "gfe-details",
        name: "issues.disputeDetailsGfeDetails",
        component: () => import('../view/issues/steps/DisputeDetailsGfeDetails.vue'),
        meta: { requiresAuth: true, layout: "IssueLayout" },
      },
      {
        path: "disclosure-of-services-and-prices",
        name: "issues.disclosureOfServicesAndPrices",
        component: () => import('../view/issues/steps/DisclosureOfServicesAndPrices.vue'),
        meta: { requiresAuth: true, layout: "IssueLayout" },
      },
      {
        path: "overview",
        name: "issues.overview",
        component: () => import('../view/issues/steps/Overview.vue'),
        meta: { requiresAuth: true, layout: "IssueLayout" },
      },
      {
        path: "letter-of-dispute",
        name: "issues.letterOfDispute",
        component: () => import('../view/issues/steps/LetterOfDispute.vue'),
        meta: { requiresAuth: true, layout: "IssueLayout" },
      },
      // {
      //   path: "payment-page",
      //   name: "issues.paymentPage",
      //   component: () => import('../view/PaymentPage.vue'),
      //   meta: { requiresAuth: true, },
      // },
      {
        path: "next-steps",
        name: "issues.nextSteps",
        component: () => import('../view/NextSteps.vue'),
        meta: { requiresAuth: true, layout: "IssueLayout" },
      },
      {
        path: "status-and-feedback",
        name: "issues.statusAndFeedback",
        component: () => import('../view/feedback/StatusAndFeedback.vue'),
        meta: { requiresAuth: true, layout: "IssueLayout" },
      },
      {
        path: "satisfaction-survey",
        name: "issues.satisfactionSurvey",
        component: () => import('../view/feedback/SatisfactionSurvey.vue'),
        meta: { requiresAuth: true, layout: "IssueLayout" },
      },
      {
        path: "dissatisfaction-survey",
        name: "issues.dissatisfactionSurvey",
        component: () => import('../view/feedback/DissatisfactionSurvey.vue'),
        meta: { requiresAuth: true, layout: "IssueLayout" },
      }
      // {
      //   path: "lack-of-response",
      //   name: "issues.lackOfResponse",
      //   component: () => import('../view/LackOfResponse.vue'),
      //   meta: { requiresAuth: true, layout: "IssueLayout" },
      // },
    ],
  },
  // {
  //   path: "/next-steps",
  //   name: "next-steps",
  //   component: () => import('../view/NextSteps.vue'),
  //   meta: { requiresAuth: true, layout: "IssueLayout" },
  // },
  {
    path: '/thank-you',
    name: 'issues.thankYou',
    component: () => import('@/modules/core/pages/ThankYouPage.vue'),
    meta: {
      // requiresAuth: true,
      layout: 'CustomerDashboardLayout',
    },
  },

  {
    path: "/patient-fairness",
    name: "patient-fairness",
    beforeEnter() {
      location.href = "https://patientfairness.com";
    },
  },
  {
    path: "/something-went-wrong",
    name: "error",
    component: () => import('../view/Error.vue'),
    meta: { requiresAuth: false, layout: 'CustomerDashboardLayout' }
  },
  {
    path: "/:pathMatch(.*)*",
    name: "issues.notFound",
    component: () => import('../view/NotFound.vue'),
    meta: { requiresAuth: true, layout: "CustomerDashboardLayout" },
  },
];

const history = createWebHistory();

const router = createRouter({
  history,
  routes,
  scrollBehavior(to, from) {
    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' };
    } else {
      return { top: 0, behavior: 'smooth' };
    }
  },
});

router.beforeEach((to, from, next) => {
  if (to.meta.title) document.title = `${to.meta.title}${to.params.slug ? `${to.params.slug}` : ''} | Patient Fairness`;
  next();
});

// hook MSAL into router
const client = new VueNavigationClient(router);

registerGuard(router);

export default router;
